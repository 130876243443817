
    import { Component, Vue } from 'vue-property-decorator'
    let xiaoxueshuxue = require('./../../../assets/imgs/xiaoxueshuxue.png'),
        zhongshu = require('./../../../assets/imgs/zhongshu.png'),
        zhongwu = require('./../../../assets/imgs/zhongwu.png'),
        zhonghua = require('./../../../assets/imgs/zhonghua.png'),
        shuxue = require('./../../../assets/imgs/xiaoshuxue.png')
    @Component({
        name: 'CurriculumSystem'
    })
    export default class CurriculumSystem extends Vue {
        // private curSysList: Array<string> = ['小学数学', '中学数学', '中学物理', '中学化学']
        private curSysList: Array<string> = ['数学']
        private activeIndex: number = 0
        private imgArr: Array<any> = [shuxue, xiaoxueshuxue, zhongshu, zhongwu, zhonghua ]
    }
