
    import { Component, Vue, Watch, Emit } from 'vue-property-decorator'
    import { mapGetters, mapMutations, mapActions } from 'vuex'

    import { Caxios } from './../../../../utils/axios'

    @Component({
        name: 'TeacherList',
        computed: mapGetters('teacher', [ 'teacherId', 'teacherItemData' ]),
        methods: {
            ...mapMutations('teacher', ['setTeacherId']),
            ...mapActions('teacher', ['setTeacherItemData'])
        }
    })
    export default class TeacherList extends Vue {
        teacherId!: number
        teacherItemData!: any
        setTeacherId!: any
        setTeacherItemData!: any

        private curTeacherId: number = -1
        private curTeacherItemData: any = null

        @Watch('teacherId', { immediate: true, deep: true })
        teacherIdWatch(newVal: number): void {
            if(newVal != -1) {
                this.curTeacherId = newVal
                this.getTeacherDetail()
            }
        }
        @Watch('teacherItemData', { immediate: true, deep: true })
        teacherItemDataWatch(newVal: any): void {
            if(newVal && newVal.id) {
                this.curTeacherItemData = newVal
            }
        }

        formatSessionStorage (): void {
            sessionStorage.removeItem('aboutUs_teacherIntroduced_teacherId')
            sessionStorage.setItem('aboutUs_teacherIntroduced_componentName', 'TeacherList')
        }
        @Emit()
        private teacherItem (): void {
            this.formatSessionStorage()
        }

        private async getTeacherDetail () {
            let result = await Caxios.get({ url: `/api//official/teacher/${this.curTeacherId}?id=${this.curTeacherId}` })
            this.setTeacherItemData(result)
        }

        private signUpEvent (): void {
            let _this: any = this
            _this.$router.push('/course/index')
            this.formatSessionStorage()
        }
        beforeMount() {
            if(this.teacherId == -1) {
                let _id: any = sessionStorage.getItem('aboutUs_teacherIntroduced_teacherId')
                if(_id) {
                    this.curTeacherId = Number(_id)
                }
            } else {
                this.curTeacherId = this.teacherId
            }
            this.setTeacherId(this.curTeacherId)
        }
    }
