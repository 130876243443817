
    import { Component, Vue, Watch, Emit } from 'vue-property-decorator'
    import { mapGetters, mapActions, mapMutations } from 'vuex'

    import { navItem } from './../../../../store/interface'
    import MineSelect from './../../../../components/mineSelect/index.vue'

    import { Caxios } from './../../../../utils/axios'

    @Component({
        name: 'TeacherList',
        components: { MineSelect },
        computed: {
            ...mapGetters('teacher', [ 'teacherNav', 'teacher', 'loadingFlag' ]),
            ...mapGetters('course', ['grade'])
        },
        methods: {
            ...mapActions('teacher', ['setTeacherList']),
            ...mapMutations('teacher', ['setTeacherId'])
        }
    })
    export default class TeacherList extends Vue {
        teacherNav!: Array<navItem>
        grade!: Array<any>
        teacher!: Array<any>
        loadingFlag!: boolean
        setTeacherList!: any
        setTeacherId!: any
        
        private activeIndex: number = 0
        private curTeacherList: Array<any> = []
        private curLoadingFlag: boolean = true
        private curGradeList: Array<any> = []

        private selectObj: any = {
            subject: 1,
            grade: '',
            name: '',
            type: 1
        }


        @Watch('teacher', { immediate: true, deep: true })
        teacherWatch(newVal: Array<any>): void {
            if(newVal && newVal.length > 0) {
                this.curTeacherList = [ ...newVal ]
            } else {
                this.curTeacherList = []
            }
        }
        @Watch('loadingFlag', { immediate: true, deep: true })
        loadingFlagWatch(newVal: boolean): void {
            this.curLoadingFlag = newVal
        }
        @Watch('grade', { immediate: true, deep: true })
        gradeWatch(newVal: Array<any>): void {
            if(newVal && Array.isArray(newVal) && newVal.length > 0) {
                this.curGradeList = [ ...newVal ]
            } else {
                this.curGradeList = []
            }
        }
        private curSetTeacherList (): void {
            this.setTeacherList(this.selectObj)
        }
        private changeActiveIndex (index: number, id: string): void {
            this.activeIndex = index
            this.selectObj.subject = Number(id)
            this.curSetTeacherList()
        }
        @Emit()
        private teacherList (item: any): void {
            sessionStorage.setItem('aboutUs_teacherIntroduced_teacherId', item.id)
            this.setTeacherId(item.id)
        }

        private changeEvent (obj: any): void {
            this.selectObj.grade = obj.id
            this.curSetTeacherList()
        }
        private inputChangeEvent (): void {
            this.curSetTeacherList()
        }
        beforeMount() {
            this.curSetTeacherList()
        }
    }
